import { FC, memo, useMemo } from 'react';

import BaseAssetDetailTable from 'components/BaseAssetDetailTable';
import BaseAssetDetailTitle from 'components/BaseAssetDetailTitle';
import PositionByAccountAssetTable from 'components/PositionByAccountAssetTable';
import from from 'styles/responsive';
import {
  formatAmount,
  formatAmountWithDecimals,
  formatNumberDecimals,
} from 'utils/amounts';
import { formatDate } from 'utils/dates';
import up from 'utils/paths';
import { formatPercentage } from 'utils/percentages';
import useMedia from 'utils/useMedia';

import {
  Button,
  Chevron,
  Container,
  GoBackContainer,
  Header,
  Title,
  Variant,
} from './styles';
import type { Props } from './types';

const FixedIncomeAssetDetailTable: FC<Props> = ({
  accruedInterest,
  couponsReceived,
  pnLExclCpn,
  aIatPurchase,
  duration,
  marketPrice,
  marketValue,
  cost,
  maturityDate,
  nominalAmount,
  parentUrl,
  title,
  totalProfitLoss,
  yieldToWorst,
  tey,
  ttc,
  rating,
  fiMultiplier,
  positionByAccountAssets,
  url = '',
  categorySlug,
}) => {
  const isTablet = useMedia(from.tablet.query);

  const assetDetailsRows = useMemo(
    () => [
      {
        id: 1,
        key: 'Nominal Amount',
        value: formatNumberDecimals(nominalAmount),
      },
      {
        id: 2,
        key: 'Market Price',
        value: formatAmountWithDecimals(marketPrice),
      },
      {
        id: 3,
        key: 'Accrued Interest',
        value: formatAmount(accruedInterest),
      },
      {
        id: 4,
        key: 'Market Value',
        value: formatAmount(marketValue),
      },
      {
        id: 5,
        key: 'Cost',
        value: formatAmount(cost),
      },
      {
        id: 6,
        key: 'Average Cost per Unit',
        value: formatAmountWithDecimals(
          (cost / nominalAmount) * (fiMultiplier === 1 ? 1 : 100),
        ),
      },
      {
        id: 7,
        key: 'Accrued Interest at Purchase',
        value: formatAmount(aIatPurchase),
      },
      {
        id: 8,
        key: 'Profit/Loss Excluding Coupons',
        value: formatAmount(pnLExclCpn),
      },
      {
        id: 9,
        key: 'Coupons Received',
        value: formatAmount(couponsReceived),
      },
      {
        id: 10,
        key: 'Total Profit / Loss',
        value: formatAmount(totalProfitLoss),
        isColored: true,
        isNegative: totalProfitLoss < 0,
      },
    ],
    [
      nominalAmount,
      marketPrice,
      accruedInterest,
      marketValue,
      cost,
      aIatPurchase,
      pnLExclCpn,
      couponsReceived,
      totalProfitLoss,
      fiMultiplier,
    ],
  );

  const characteristicsRows = useMemo(
    () => [
      {
        id: 1,
        key: 'Yield to Worst',
        value: formatPercentage(yieldToWorst),
      },
      {
        id: 3,
        key: 'Time to Call',
        value: formatNumberDecimals(ttc),
      },
      {
        id: 4,
        key: 'Duration',
        value: formatNumberDecimals(duration),
      },
      {
        id: 5,
        key: 'Maturity Date',
        value: formatDate(maturityDate),
      },
      {
        id: 6,
        key: 'Rating',
        value: rating,
      },
    ],
    [yieldToWorst, ttc, duration, maturityDate, rating],
  );

  if (
    yieldToWorst !== tey &&
    !characteristicsRows.find((item) => item.id === 2)
  ) {
    characteristicsRows.splice(1, 0, {
      id: 2,
      key: 'Tax Equivalent Yield to Worst',
      value: formatPercentage(tey),
    });
  }

  return (
    <Container>
      <Header id="header">
        {isTablet && (
          <GoBackContainer>
            <Button
              color="secondary"
              variant="only-icon"
              size="small"
              withIcon={<Chevron />}
              href={parentUrl ?? up(url, 2)}
            />
            <Variant>Fixed Income</Variant>
          </GoBackContainer>
        )}
        <Title>{title}</Title>
        <BaseAssetDetailTitle title="Asset Details" />
      </Header>
      <BaseAssetDetailTable rows={assetDetailsRows} />
      <Header>
        <BaseAssetDetailTitle title="Characteristics" />
      </Header>
      <BaseAssetDetailTable rows={characteristicsRows} />
      {isTablet && (
        <>
          <Header>
            <BaseAssetDetailTitle title="Position by Account" />
          </Header>
          <PositionByAccountAssetTable
            data={positionByAccountAssets}
            categorySlug={categorySlug}
            nameExtraData="Coupons"
          />
        </>
      )}
    </Container>
  );
};

export default memo(FixedIncomeAssetDetailTable);
