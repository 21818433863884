import { useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import useSelectedClient from 'api/hooks/clients/useSelectedClient';
import up from 'utils/paths';
import searchWithoutSort from 'utils/searchWithoutSort';
import type { KeySortOfColumn } from 'utils/sort/types';

import { useCurrentGrouping } from '../CategoryDetail/logic';
import {
  useFilteringSponsors,
  useFilteringSubtypes,
} from '../CategoryDetailFixedIncome/logic';
import { useSummary } from '../CategoryDetailPrivateInvestments/logic';
import type { Position } from './Controls/types';
import { useData, useSorting } from './logic';

export default function useConnect() {
  const [position, setPosition] = useState<Position>('open');

  const { url } = useRouteMatch();
  const { selectedPortfolio } = useSelectedClient();
  const { replace } = useHistory();
  const { hash, pathname, state, search } = useLocation();
  const grouping = useCurrentGrouping({ search });
  const { currentSorting, onChangeSorting, compare } = useSorting();

  const {
    currentFiltering: currentFilteringSubtypes,
    multipleOptions: multipleSubtypesOptions,
    filterOptions: filterSubtypesOptions,
    onChangeFiltering: onChangeFilteringSubtypes,
  } = useFilteringSubtypes({
    categorySlug: 'private-investments',
    portfolio: selectedPortfolio?.id,
    position,
  });

  const {
    currentFiltering: currentFilteringSponsors,
    multipleOptions: multipleSponsorsOptions,
    filterOptions: filterSponsorsOptions,
    onChangeFiltering: onChangeFilteringSponsors,
  } = useFilteringSponsors({
    categorySlug: 'private-investments',
    portfolio: selectedPortfolio?.id,
    position,
  });

  const { assets, balance, mobileRows, MOI, subtypes, sponsors } = useData({
    portfolio: selectedPortfolio?.id,
    url,
    grouping,
    position,
    compare,
    multipleSubtypesOptions,
    multipleSponsorsOptions,
  });

  const summary = useSummary({
    assets,
  });

  const changePosition = (value: Position) => {
    setPosition(value);

    replace({
      hash,
      pathname,
      state,
    });
  };

  const parentUrl = `${up(url)}${searchWithoutSort(search)}`;

  const headerColumns: KeySortOfColumn[] = [
    {
      titleColumn: `${assets?.length} Assets`,
      keyOrderAsc: 'alphabeticalR',
      keyOrderDesc: 'alphabetical',
    },
    {
      titleColumn: 'Market Value',
      keyOrderAsc: 'marketValueASC',
      keyOrderDesc: 'default',
    },
    {
      titleColumn: 'Commitment',
      keyOrderAsc: 'commitmentASC',
      keyOrderDesc: 'commitmentDESC',
    },
    {
      titleColumn: 'Capital Called',
      keyOrderAsc: 'capitalCalledASC',
      keyOrderDesc: 'capitalCalledDESC',
    },
    {
      titleColumn: 'To Be Called',
      keyOrderAsc: 'toBeCalledASC',
      keyOrderDesc: 'toBeCalledDESC',
    },
    {
      titleColumn: 'Distributions',
      keyOrderAsc: 'distributionsASC',
      keyOrderDesc: 'distributionsDESC',
    },
    {
      titleColumn: 'P&L ($)',
      keyOrderAsc: 'pandlASC',
      keyOrderDesc: 'pandlDESC',
    },
    {
      titleColumn: 'MOI',
      keyOrderAsc: 'moiASC',
      keyOrderDesc: 'moiDESC',
    },
    {
      titleColumn: 'Net IRR',
      keyOrderAsc: 'netIRRASC',
      keyOrderDesc: 'netIRRDESC',
    },
  ];

  return {
    assets,
    balance,
    mobileRows,
    MOI,
    parentUrl,
    search,
    summary,
    url,
    subtypes,
    sponsors,
    position,
    handle: {
      changePosition,
      changeSorting: onChangeSorting,
      changeFilteringSubtypes: onChangeFilteringSubtypes,
      changeFilteringSponsors: onChangeFilteringSponsors,
    },
    currentFilteringSubtypes,
    currentFilteringSponsors,
    currentSorting,
    filterSubtypesOptions,
    filterSponsorsOptions,
    headerColumns,
  };
}
