import { useMemo } from 'react';

import type { Asset } from 'api/hooks/allocations/useAllocationAssets';
import type { AssetGrouping } from 'model/AssetGrouping';
import { formatAmount, formatNumberDecimals } from 'utils/amounts';
import { formatPercentage } from 'utils/percentages';
import getPrivateInvestmentsSummary from 'utils/privateInvestments/getPrivateInvestmentsSummary';
import type { FilterOption } from 'utils/useDataManipulation/useEntityFiltering';
import useEntitySorting, {
  SortOption,
} from 'utils/useDataManipulation/useEntitySorting';

import { useAssets } from '../CategoryDetail/logic';
import type { Position } from './Controls/types';

type AssetFilterOption = FilterOption<Asset>;
type AssetSortOption = SortOption<Asset>;

export function useData({
  portfolio,
  url,
  grouping,
  position,
  compare,
  multipleSubtypesOptions,
  multipleSponsorsOptions,
}: {
  portfolio: string | undefined;
  url: string;
  grouping: { groupBy: AssetGrouping; subselection: string } | undefined;
  position: Position;
  compare: AssetSortOption['compare'];
  multipleSubtypesOptions?: AssetFilterOption[];
  multipleSponsorsOptions?: AssetFilterOption[];
}) {
  const { assets } = useAssets({
    categorySlug: 'private-investments',
    compare,
    grouping,
    portfolio,
    url,
  });

  const fullAssets = useMemo(
    () =>
      assets?.map(
        (asset) =>
          ({
            ...asset,
            date: Date.now(),
          } as const),
      ) ?? [],
    [assets],
  );

  const valueSubtypesOptions =
    multipleSubtypesOptions && multipleSubtypesOptions.map((f) => f.label);

  const filteredBySubtypes = useMemo(
    () =>
      valueSubtypesOptions &&
      valueSubtypesOptions?.length > 0 &&
      !valueSubtypesOptions.includes('All')
        ? [...fullAssets].filter((d) =>
            valueSubtypesOptions.includes(d.subtype),
          )
        : fullAssets,
    [valueSubtypesOptions, fullAssets],
  );

  const valueSponsorsOptions =
    multipleSponsorsOptions && multipleSponsorsOptions.map((f) => f.label);

  const filteredBySponsors = useMemo(
    () =>
      valueSponsorsOptions &&
      valueSponsorsOptions?.length > 0 &&
      !valueSponsorsOptions.includes('All')
        ? [...filteredBySubtypes].filter((d) =>
            valueSponsorsOptions.includes(d.sponsor),
          )
        : filteredBySubtypes,
    [valueSponsorsOptions, filteredBySubtypes],
  );

  const filteredByPosition = useMemo(() => {
    switch (position) {
      case 'open':
        return filteredBySponsors.filter((item) => !item.closedPosition);
      case 'closed':
        return filteredBySponsors.filter((item) => item.closedPosition);
      case 'all':
      default:
        return filteredBySponsors;
    }
  }, [filteredBySponsors, position]);

  const balance = useMemo(
    () => filteredByPosition.reduce((acc, item) => acc + item.value, 0),
    [filteredByPosition],
  );

  const summary = useMemo(
    () => getPrivateInvestmentsSummary(filteredByPosition),
    [filteredByPosition],
  );

  const MOI = useMemo(() => {
    const calculatedMOI =
      (summary.value + summary.distributions) / summary.grossCapitalCall;
    if (isFinite(calculatedMOI)) {
      return formatNumberDecimals(calculatedMOI);
    }
    return '-';
  }, [summary.value, summary.distributions, summary.grossCapitalCall]);

  const mobileRows = useMemo(
    () => [
      {
        id: 1,
        key: 'Market Value',
        value: formatAmount(summary.value),
      },
      {
        id: 2,
        key: '(%) Total Portfolio at Market Value',
        value: formatPercentage(summary.allocation),
      },
      {
        id: 3,
        key: '(%) Total Portfolio at Cost',
        value: formatPercentage(summary.totalPortfolioAtCostPercent),
      },
      {
        id: 4,
        key: 'Commitment',
        value: formatAmount(summary.capitalCommitment),
      },
      {
        id: 5,
        key: 'Capital Called',
        value: formatAmount(summary.grossCapitalCall),
      },
      {
        id: 6,
        key: 'To Be Called',
        value: formatAmount(summary.toBeCalled),
      },
      {
        id: 7,
        key: 'Distributions',
        value: formatAmount(summary.distributions),
      },
      {
        id: 8,
        key: 'P&L ($)',
        value: formatAmount(summary.contDollars),
        isColored: true,
        isNegative: summary.contDollars < 0,
      },
      {
        id: 9,
        key: 'MOI',
        value: MOI,
      },
    ],
    [MOI, summary],
  );

  return {
    assets: filteredByPosition,
    balance,
    mobileRows,
    MOI,
    subtypes: valueSubtypesOptions,
    sponsors: valueSponsorsOptions,
  };
}

// noinspection DuplicatedCode - This is abstract enough
export function useSorting() {
  const sortOptions: readonly [AssetSortOption, ...AssetSortOption[]] = useMemo(
    () => [
      {
        compare: (a, b) => b.value - a.value,
        label: 'Market Value: High to Low',
        value: 'default',
      },
      {
        compare: (a, b) => a.value - b.value,
        label: 'Market Value: Low to High',
        value: 'marketValueASC',
      },
      {
        compare: (a, b) => b.capitalCommitment - a.capitalCommitment,
        label: 'Commitment: High to Low',
        value: 'commitmentDESC',
      },
      {
        compare: (a, b) => a.capitalCommitment - b.capitalCommitment,
        label: 'Commitment: Low to High',
        value: 'commitmentASC',
      },
      {
        compare: (a, b) => b.capitalCalled - a.capitalCalled,
        label: 'Capital Called: High to Low',
        value: 'capitalCalledDESC',
      },
      {
        compare: (a, b) => a.capitalCalled - b.capitalCalled,
        label: 'Capital Called: Low to High',
        value: 'capitalCalledASC',
      },
      {
        compare: (a, b) => b.toBeCalled - a.toBeCalled,
        label: 'To Be Called: High to Low',
        value: 'toBeCalledDESC',
      },
      {
        compare: (a, b) => a.toBeCalled - b.toBeCalled,
        label: 'To Be Called: Low to High',
        value: 'toBeCalledASC',
      },
      {
        compare: (a, b) => b.distributions - a.distributions,
        label: 'Distributions: High to Low',
        value: 'distributionsDESC',
      },
      {
        compare: (a, b) => a.distributions - b.distributions,
        label: 'Distributions: Low to High',
        value: 'distributionsASC',
      },
      {
        compare: (a, b) => b.contDollars - a.contDollars,
        label: 'P&L ($): High to Low',
        value: 'pandlDESC',
      },
      {
        compare: (a, b) => a.contDollars - b.contDollars,
        label: 'P&L ($): Low to High',
        value: 'pandlASC',
      },
      {
        compare: (a, b) => b.moi - a.moi,
        label: 'MOI: High to Low',
        value: 'moiDESC',
      },
      {
        compare: (a, b) => a.moi - b.moi,
        label: 'MOI: Low to High',
        value: 'moiASC',
      },
      {
        compare: (a, b) => (b.netIRR ?? 0) - (a.netIRR ?? 0),
        label: 'Net IRR: High to Low',
        value: 'netIRRDESC',
      },
      {
        compare: (a, b) => (a.netIRR ?? 0) - (b.netIRR ?? 0),
        label: 'Net IRR: Low to High',
        value: 'netIRRASC',
      },
      {
        compare: (a, b) => a.name.localeCompare(b.name),
        label: 'Alphabetical: A to Z',
        value: 'alphabetical',
      },
      {
        compare: (a, b) => b.name.localeCompare(a.name),
        label: 'Alphabetical: Z to A',
        value: 'alphabeticalR',
      },
    ],
    [],
  );

  return useEntitySorting({ sortOptions });
}
