/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { FC, memo, useState } from 'react';

import Amount from 'components/Amount';
import { calculateIncomeTypeAmountTotals } from 'containers/Protected/IncomeProjection/logic';

import {
  Col,
  ColMoreInfo,
  Header,
  HeaderCell,
  LoaderContainer,
  MinusIcon,
  MoreInfo,
  MoreInfoTable,
  PlusIcon,
  Row,
  RowAccount,
  RowEntity,
  Spinner,
  Table,
} from './styles';
import type { Props } from './types';

const SummaryIncomeProjectionTable: FC<Props> = ({
  rows,
  rowTotalsByEntity,
  total,
  loading,
}) => {
  const [selectedRowMonth, setSelectedRowMonth] = useState<string | undefined>(
    undefined,
  );
  const [selectedRowEntity, setSelectedRowEntity] = useState<
    string | undefined
  >(undefined);

  const incomeTypeAmountTotals = calculateIncomeTypeAmountTotals(rows);

  const rowMonthLevel = (
    id: string,
    name: string,
    incomeTypeAmount: Record<string, number>,
    amount: number,
  ) => (
    <Row
      key={id}
      $incomeTypeLength={Object.keys(incomeTypeAmountTotals).length}
      $isLastRow={id === 'last-row'}
    >
      <Col>{name}</Col>
      {Object.keys(incomeTypeAmountTotals).map((header) => (
        <Col key={`${id}_${header}`}>
          {incomeTypeAmount[header] && incomeTypeAmount[header] !== 0 ? (
            <Amount value={incomeTypeAmount[header] || 0} withDecimals />
          ) : (
            '-'
          )}
        </Col>
      ))}
      <Col>{amount !== 0 ? <Amount value={amount} withDecimals /> : '-'}</Col>
      {amount !== 0 ? (
        <ColMoreInfo
          onClick={() =>
            setSelectedRowMonth(selectedRowMonth !== id ? id : undefined)
          }
        >
          {selectedRowMonth === id ? <MinusIcon /> : <PlusIcon />}
        </ColMoreInfo>
      ) : (
        <div />
      )}
    </Row>
  );

  const rowEntityLevel = (
    id: string,
    name: string,
    incomeTypeAmount: Record<string, number>,
    amount: number,
  ) => (
    <RowEntity
      key={`${id}_${name}`}
      $incomeTypeLength={Object.keys(incomeTypeAmountTotals).length}
    >
      <Col>{name}</Col>
      {Object.keys(incomeTypeAmountTotals).map((header) => (
        <Col key={`${id}_${header}`}>
          {incomeTypeAmount[header] && incomeTypeAmount[header] !== 0 ? (
            <Amount value={incomeTypeAmount[header] || 0} withDecimals />
          ) : (
            '-'
          )}
        </Col>
      ))}
      <Col>{amount !== 0 ? <Amount value={amount} withDecimals /> : '-'}</Col>
      {amount !== 0 ? (
        <ColMoreInfo
          onClick={() =>
            setSelectedRowEntity(
              selectedRowEntity !== `${id}_${name}`
                ? `${id}_${name}`
                : undefined,
            )
          }
        >
          {selectedRowEntity === `${id}_${name}` ? <MinusIcon /> : <PlusIcon />}
        </ColMoreInfo>
      ) : (
        <div />
      )}
    </RowEntity>
  );

  const rowAccountLevel = (
    id: string,
    nameEntity: string,
    nameAccount: string,
    incomeTypeAmount: Record<string, number>,
    amount: number,
  ) => (
    <RowAccount
      key={`${id}_${nameEntity}_${nameAccount}`}
      $incomeTypeLength={Object.keys(incomeTypeAmountTotals).length}
    >
      <Col>{nameAccount}</Col>
      {Object.keys(incomeTypeAmountTotals).map((header) => (
        <Col key={`${id}_${nameEntity}_${nameAccount}_${header}`}>
          {incomeTypeAmount[header] && incomeTypeAmount[header] !== 0 ? (
            <Amount value={incomeTypeAmount[header] || 0} withDecimals />
          ) : (
            '-'
          )}
        </Col>
      ))}
      <Col>{amount !== 0 ? <Amount value={amount} withDecimals /> : '-'}</Col>
      <div />
    </RowAccount>
  );

  return (
    <Table>
      <Header $incomeTypeLength={Object.keys(incomeTypeAmountTotals).length}>
        <HeaderCell key="Month">Month</HeaderCell>
        {Object.keys(incomeTypeAmountTotals).map((header) => (
          <HeaderCell key={header}>{header}</HeaderCell>
        ))}
        <HeaderCell key="Total">Total</HeaderCell>
      </Header>
      {!loading &&
        rows.map((row) => (
          <div key={row.id}>
            {rowMonthLevel(row.id, row.name, row.incomeTypeAmount, row.amount)}
            {selectedRowMonth === row.id && (
              <MoreInfo>
                <MoreInfoTable
                  $columns={Object.entries(row.incomeTypeAmount).length}
                >
                  {row.rowsByEntity?.incomeTypeAmountByEntity.map(
                    (rowEntity: any) => (
                      <div key={`${row.id}_${rowEntity.entity}`}>
                        {rowEntityLevel(
                          row.id,
                          rowEntity.entity,
                          rowEntity.incomeTypeAmount,
                          rowEntity.amountTotal,
                        )}
                        {selectedRowEntity ===
                          `${row.id}_${rowEntity.entity}` && (
                          <MoreInfo>
                            <MoreInfoTable
                              $columns={
                                Object.entries(row.incomeTypeAmount).length
                              }
                            >
                              {rowEntity.rowsByAccount?.incomeTypeAmountByAccount.map(
                                (rowAccount: any) => (
                                  <div
                                    key={`${row.id}_${rowEntity.entity}_${rowAccount.account}`}
                                  >
                                    {rowAccountLevel(
                                      row.id,
                                      rowEntity.entity,
                                      rowAccount.account,
                                      rowAccount.incomeTypeAmount,
                                      rowAccount.amountTotal,
                                    )}
                                  </div>
                                ),
                              )}
                            </MoreInfoTable>
                          </MoreInfo>
                        )}
                      </div>
                    ),
                  )}
                </MoreInfoTable>
              </MoreInfo>
            )}
          </div>
        ))}
      {!loading && rows.length > 0 && (
        <div key="last-row">
          {rowMonthLevel('last-row', 'Total', incomeTypeAmountTotals, total)}
          {selectedRowMonth === 'last-row' && (
            <MoreInfo>
              <MoreInfoTable
                $columns={Object.keys(incomeTypeAmountTotals).length}
              >
                {rowTotalsByEntity.map((rowEntity) => (
                  <div key={`last-row_${rowEntity.entity}`}>
                    {rowEntityLevel(
                      'last-row',
                      rowEntity.entity,
                      rowEntity.accumulatedIncomeTypeAmount,
                      rowEntity.amountTotal,
                    )}
                    {selectedRowEntity === `last-row_${rowEntity.entity}` && (
                      <MoreInfo>
                        <MoreInfoTable
                          $columns={
                            Object.entries(
                              rowEntity.accumulatedIncomeTypeAmount,
                            ).length
                          }
                        >
                          {rowEntity.rowsByAccount.map((rowAccount: any) => (
                            <div
                              key={`last-row_${rowEntity.entity}_${rowAccount.account}`}
                            >
                              {rowAccountLevel(
                                'last-row',
                                rowEntity.entity,
                                rowAccount.account,
                                rowAccount.incomeTypeAmountTotalsByAccount,
                                rowAccount.amountTotal,
                              )}
                            </div>
                          ))}
                        </MoreInfoTable>
                      </MoreInfo>
                    )}
                  </div>
                ))}
              </MoreInfoTable>
            </MoreInfo>
          )}
        </div>
      )}
      {loading && (
        <LoaderContainer>
          <Spinner />
        </LoaderContainer>
      )}
    </Table>
  );
};

export default memo(SummaryIncomeProjectionTable);
